var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upsell-wrapper" },
    [
      _c("Modal", {
        ref: "modal",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "disabled-proceed-to-checkout__content" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "33",
                            height: "40",
                            viewBox: "0 0 33 40",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M3 12.5V37.5C3 38.875 4.125 40 5.5 40H28C29.375 40 30.5 38.875 30.5 37.5V12.5H3ZM10.5 35H8V17.5H10.5V35ZM15.5 35H13V17.5H15.5V35ZM20.5 35H18V17.5H20.5V35ZM25.5 35H23V17.5H25.5V35ZM31.125 5H23V1.875C22.998 1.37833 22.7998 0.90256 22.4486 0.551357C22.0974 0.200153 21.6217 0.00197487 21.125 0L12.375 0C11.8783 0.00197487 11.4026 0.200153 11.0514 0.551357C10.7002 0.90256 10.502 1.37833 10.5 1.875V5H2.375C1.87812 5.00132 1.40198 5.19929 1.05063 5.55063C0.699287 5.90198 0.50132 6.37812 0.5 6.875V10H33V6.875C32.9987 6.37812 32.8007 5.90198 32.4494 5.55063C32.098 5.19929 31.6219 5.00132 31.125 5ZM20.5 5H13V2.5325H20.5V5Z",
                              fill: "#004C59"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("h4", [
                        _vm._v("Twój koszyk jest pusty, wybierz produkty.")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "button-box" }, [
                      _c(
                        "button",
                        {
                          staticClass: "custom-modal-cancel",
                          attrs: { "aria-label": "Cancel" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$refs.modal.CloseModal()
                            }
                          }
                        },
                        [_c("span", [_vm._v("Zamknij")])]
                      )
                    ])
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }