<template>
    <div class="upsell-wrapper">
        <Modal ref="modal">
            <template #content>
                <div class="disabled-proceed-to-checkout__content">
                    <div class="title">
                        <svg
                            width="33"
                            height="40"
                            viewBox="0 0 33 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3 12.5V37.5C3 38.875 4.125 40 5.5 40H28C29.375 40 30.5 38.875 30.5 37.5V12.5H3ZM10.5 35H8V17.5H10.5V35ZM15.5 35H13V17.5H15.5V35ZM20.5 35H18V17.5H20.5V35ZM25.5 35H23V17.5H25.5V35ZM31.125 5H23V1.875C22.998 1.37833 22.7998 0.90256 22.4486 0.551357C22.0974 0.200153 21.6217 0.00197487 21.125 0L12.375 0C11.8783 0.00197487 11.4026 0.200153 11.0514 0.551357C10.7002 0.90256 10.502 1.37833 10.5 1.875V5H2.375C1.87812 5.00132 1.40198 5.19929 1.05063 5.55063C0.699287 5.90198 0.50132 6.37812 0.5 6.875V10H33V6.875C32.9987 6.37812 32.8007 5.90198 32.4494 5.55063C32.098 5.19929 31.6219 5.00132 31.125 5ZM20.5 5H13V2.5325H20.5V5Z"
                                fill="#004C59"
                            />
                        </svg>
                        <h4>Twój koszyk jest pusty, wybierz produkty.</h4>
                    </div>

                    <div class="button-box">
                        <button
                            class="custom-modal-cancel"
                            aria-label="Cancel"
                            @click.prevent="$refs.modal.CloseModal()"
                        >
                            <span>Zamknij</span>
                        </button>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import cartUtils from './mixins'
import Modal from '../partials/Modal.vue'

export default {
    components: { Modal },
    scopeName: 'proceed-to-checkout',
    mixins: [cartUtils],
    data() {
        return {
            orderButtons: {},
            customerActiveCheckbox: {},
            customerActiveAllCheckbox: {},
            productForms: {},
            selectedProduct: [],
            unavailableProduct: [],
            unavailableProductSelected: []
        }
    },
    mounted() {
        this.productForms = document
            .getElementById('product-forms')
            .querySelectorAll('form')
        this.orderButtons = document.querySelectorAll('.btn-proceed-checkout')
        this.customerActiveCheckbox = document.querySelectorAll(
            '.customer_active_checkbox'
        )
        this.customerActiveAllCheckbox = document.querySelector(
            '.customer_active_all_checkbox'
        )

        this.orderButtons.forEach((i) => {
            i.addEventListener('click', () => {
                if (i.classList.contains('disable')) {
                    this.$refs.modal.OpenModal()
                } else this.proceedToCheckout(i.dataset.url)
            })
        })
        this.$nextTick(() => {
            this.collectSelectedProducts()
            this.collectUnavailable()
            this.collectUnavailableSelected()
            this.toggleDisabled()

            this.customerActiveCheckbox.forEach((i) => {
                i.addEventListener('change', () => {
                    this.resetElements()

                    this.collectSelectedProducts()
                    this.collectUnavailable()
                    this.collectUnavailableSelected()
                    this.toggleDisabled()
                })
            })
        })
    },
    methods: {
        resetElements() {
            this.selectedProduct = []
            this.unavailableProduct = []
            this.unavailableProductSelected = []
        },
        collectSelectedProducts() {
            this.customerActiveCheckbox.forEach((i) => {
                if (i.checked) {
                    this.selectedProduct.push(i)
                }
            })
        },
        collectUnavailable() {
            this.customerActiveCheckbox.forEach((i) => {
                const productRow = this.getProductRow(i.dataset.id)
                if (productRow.classList.contains('cart-item--unavailable')) {
                    this.unavailableProduct.push(i)
                }
            })
        },
        collectUnavailableSelected() {
            this.customerActiveCheckbox.forEach((i) => {
                const productRow = this.getProductRow(i.dataset.id)
                if (
                    productRow.classList.contains('cart-item--unavailable') &&
                    i.checked
                ) {
                    this.unavailableProductSelected.push(i)
                }
            })
        },
        toggleDisabled() {
            if (
                this.selectedProduct.length === 0 ||
                this.unavailableProduct.length ===
                    this.customerActiveCheckbox.length ||
                (this.unavailableProduct.length ===
                    this.unavailableProductSelected.length &&
                    this.unavailableProductSelected.length ===
                        this.selectedProduct.length) ||
                this.unavailableProductSelected.length ===
                    this.selectedProduct.length
            ) {
                this.orderButtons.forEach((i) => {
                    if (!i.classList.contains('disable')) {
                        i.classList.add('disable')
                    }
                })
            } else {
                this.orderButtons.forEach((i) => {
                    i.classList.remove('disable')
                })
            }
        },
        getProductRow(productId) {
            return document
                .querySelector(`#product-forms input[value="${productId}"]`)
                .closest('.cart-item')
        },
        proceedToCheckout(url) {
            window.dataLayer.push({
                event: 'checkoutOption',
                ecommerce: {
                    checkout_option: {
                        actionField: { step: 1, option: 'cart' }
                    }
                }
            })

            window.location.href = url
        }
    }
}
</script>
<style lang="scss">
.checkout-cart-index {
    .btn-proceed-checkout {
        &.disable {
            opacity: 0.2;
            cursor: default;
        }
    }
    .custom-modal {
        @media #{$media-to-sm} {
            max-width: 330px;
            position: relative;
            overflow: visible;
        }
    }

    .custom-modal-close {
        @media #{$screen} and (max-width: 359px) {
            top: 5px !important;
            right: 5px !important;
        }
        @media #{$media-to-sm} {
            top: -15px;
            right: -15px;
            padding: 4px;
            min-width: 32px;
            min-height: 32px;
            width: 32px !important;
            height: 32px !important;
            z-index: 1000;
            position: absolute;
        }
    }
    .disabled-proceed-to-checkout__content {
        display: flex;
        flex-flow: row wrap;
        gap: 10px 5px;
        max-width: 455px;
        padding: 10px;
        margin: 0 auto;

        .title {
            display: flex;
            flex: 1 0 100%;
            flex-flow: column wrap;
            justify-content: center;
            text-align: center;

            svg {
                margin: 0 auto;
            }

            h4 {
                font-size: 20px;
                font-weight: 600;
                padding-top: 20px;
            }
        }

        .button-box {
            display: flex;
            flex: 1 0 100%;
            flex-flow: row wrap;
            justify-content: center;

            button {
                font-size: 20px;
                font-weight: 700;
                text-transform: none;
                border-radius: 30px;
                min-height: auto;

                @media #{$media-to-sm} {
                    font-size: 16px;
                    min-width: 25px;
                }
            }
        }
    }
}
</style>
